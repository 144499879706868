.coin-info-container {
    width: 190px;
    height: 200px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background-color: var(--header-bg-color);
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .coin-info-container:hover {
    transform: scale(1.03);
}

  .coin-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
    justify-content: center;
}
  
  .coin-info-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 6px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  
  .header-text{
    padding-top: 5px;
  }

  .coin-info-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .coin-info-label {
    width: 40%;
    font-size: 14px;
    text-align: left;
    margin-right: 10px;
    margin-left: 6px;
  }
  
  .coin-info-input {
    width: 60%;
    font-size: 14px;
    padding: 5px 7px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    outline: none;
    background-color: var(--header-bg-color);
    color: var(--text-color);
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .remove-icon {
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
  } 

  .plus{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 72px;
    color: #808080;
    cursor: pointer;
    padding-bottom: 20px;
  }