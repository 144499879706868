.slogan-container {
    position: relative;
    text-align: center;
    background-color: var(--background-color);
    overflow: hidden;
}

.slogan-text,
.slogan-subtext {
    position: absolute;
    color: var(--text-color);
    font-family: var(--primary-font);
    text-align: left;
    transition: transform 0.3s ease, font-size 0.3s ease;
}

/* For PC version (left-top) */
.slogan-text {
    top: 5%;
    left: 5%;
    font-size: 3rem;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.slogan-subtext {
    top: 15%;
    left: 5%;
    font-size: 1.2rem;
    max-width: 500px;
    line-height: 1.5;
}

.slogan-text:hover,
.slogan-subtext:hover {
    transform: translateX(10px) scale(1.1);
}

/* Mobile version (center-top) */
@media (max-width: 768px) {
    .slogan-text,
    .slogan-subtext {
        top: 1.5%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        transition: transform 0.3s ease, font-size 0.3s ease;
    }

    .slogan-text {
        font-size: 2rem;
        white-space: nowrap;
    }

    .slogan-subtext {
        top: 9%;
        font-size: 1rem;
        width: 100%;
    }

    .slogan-text:hover,
    .slogan-subtext:hover {
        transform: translateX(-50%) scale(1.05);
    }

    .slogan-text:active,
    .slogan-subtext:active {
        animation: scaleUp 0.3s forwards;
    }

    @keyframes scaleUp {
        0% {
            transform: scale(1);
            transform: translateX(-50%);
        }
        50% {
            transform: scale(1.1);
            transform: translateX(-50%);
        }
        100% {
            transform: scale(1);
            transform: translateX(-50%);
        }
    }

}

/* Button Styles */
.start-btn {
    position: absolute;
    top: 33%;
    left: 5%;
    padding: 25px 60px;
    font-size: 1.5rem;
    background: linear-gradient(135deg, var(--button-bg-color), #0061ff);
    color: var(--button-text-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.start-btn:hover {
    background: linear-gradient(135deg, #00bbff, #00e4ff);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px) scale(1.05);
}

.dark-mode .start-btn {
    background: linear-gradient(135deg, var(--button-bg-color), #00ff00);
}

.dark-mode .start-btn:hover {
    background: linear-gradient(135deg, #00c123, #00ff00);
    box-shadow: 0 15px 30px rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
    .start-btn {
        top: 23%;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px 30px;
        border-radius: 10px;
        font-size: 1.2rem;
        transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    }

    .start-btn:hover {
        transform: translateY(-4px) scale(1.05);
        transform: translateX(-50%);
    }
}
