.create-bot {
  position: relative;
  text-align: center;
  padding: 50px 20px;
  color: var(--text-color);
  max-height: 70vh; /* Обмежує висоту блоку */
  overflow-y: auto; /* Додає вертикальну прокрутку */
}

/* Прокрутка */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--second-background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--button-hover-bg-color);
}

.create-bot h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: var(--text-color);
}

.create-bot-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.create-bot-form label {
  font-size: 1.2rem;
  color: var(--text-color);
}

.create-bot-form input,
.create-bot-form select {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.create-bot-button {
  padding: 10px 20px;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-bot-button:hover {
  background-color: var(--button-hover-bg-color);
}

@media (max-width: 768px) {
  .create-bot h1 {
      font-size: 2rem;
  }

  .create-bot-form input,
  .create-bot-form select {
      max-width: 100%;
  }
}
