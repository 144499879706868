button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.3s ease;
  }
  
  button:hover {
    color: var(--button-bg-color);
  }
  