.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: var(--header-bg-color);
  transition: box-shadow 0.3s ease;
  border-bottom: 2px solid var(--second-background-color);
}

.logo img {
  max-width: 60px;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.05);
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.header-main-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.header-main-buttons button {
  padding: 0;
}

.header-main-buttons .profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-right: 20px;
}

.header-main-buttons .profile-button:hover {
  transform: scale(1.1);
}

.navbar-main a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}

.navbar-main ul {
  display: flex;
  list-style: none;
}

.navbar-main a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--button-bg-color);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.navbar-main a:hover::after {
  width: 100%;
}

.navbar-main a:hover {
  opacity: 0.6;
}

@media (max-width: 800px) {
  .burger-menu {
    display: block;
  }
  .header-main {
    padding: 10px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
  }
}
