.profile-container {
    gap: 20px;
}

.profile-blocks {
    padding: 3%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    gap: 20px;
}

.profile-block-prising {
    grid-column: 2;
    grid-row: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    padding: 20px;
    position: relative; /* For positioning the dropdown */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-block-prising:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.profile-block-prising h2 {
    margin-top: 0;
}

.divider {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid var(--border-color);
}

.subscription-details {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.detail {
    text-align: center;
}

.number {
    font-size: 2rem;
    margin: 0;
}

.label {
    font-size: 0.9rem;
    color: var(--text-color-secondary);
}

.change-plan-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    font-family: 'Comfortaa';
}

.change-plan-btn:hover {
    background-color: var(--button-bg-color-hover);
    transform: scale(1.05);
}

.profile-block-settings {
    grid-column: 2;
    grid-row: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profile-block-settings-buttons {
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    height: 100%; /* Займає всю висоту */
}

.profile-block-settings:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.profile-block-settings-buttons button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.profile-block-statistics {
    grid-column: 1;
    grid-row: 1 / 3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-block-statistics:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: var(--text-color);
    position: absolute;
    top: 20px;
    right: 20px;
    transition: color 0.3s ease;
}

.dropdown-btn:hover {
    color: var(--button-bg-color);
}

.dropdown-content {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 60px;
    right: 20px;
    width: 250px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.profile-block-prising:hover .dropdown-content {
    opacity: 1;
    visibility: visible;
}

.dropdown-content p {
    margin: 10px 0;
    font-size: 0.9rem;
}

.dropdown-content p span {
    font-size: 0.8rem;
    color: var(--text-color-secondary);
}

@media (max-width: 768px) {
    .profile-blocks {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
    }

    .profile-block-prising,
    .profile-block-settings,
    .profile-block-statistics {
        grid-column: 1;
    }

    .profile-block-statistics {
        grid-row: 1;
    }
    .profile-block-prising {
        grid-row: 2;
    }
    .profile-block-settings {
        grid-row: 3;
    }
}
