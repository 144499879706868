.main-content {
  position: relative; /* Дозволяє дітям бути абсолютно позиційованими */
  text-align: center;
  color: var(--text-color);
}

.main-content-image {
  position: absolute; /* Абсолютне позиціонування зображення */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Робить зображення адаптивним */
  z-index: -1; /* Відправляє зображення на задній план */
  opacity: 0.5; /* Невелика прозорість, щоб текст був читабельним */
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
