.active-orders-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    padding-top: 20px;
    justify-content: center;
  }
  
  .order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .order:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .status-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: transform 0.2s ease;
  }
  
  .status-circle.green {
    background-color: #39a53f;
  }
  
  .status-circle.gray {
    background-color: #9e9e9e;
  }
  
  .status-circle:hover {
    transform: scale(1.2);
  }
  
  .order-symbol {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .order-symbol div {
    font-weight: bold;
    font-size: 14px;
  }
  
  .order-symbol small {
    color: #888;
    font-size: 12px;
  }
  
  .order-info {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .order-info div {
    font-weight: bold;
    font-size: 14px;
    justify-content: center;
    padding-bottom: 5px;
  }
  
  .order-info small {
    color: #888;
    font-size: 12px;
  }
  
  .order-info div:hover {
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .cancel-order-button {
    background: none;
    border: none;
    color: #d9534f;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .cancel-order-button:hover {
    color: #c9302c;
    transform: rotate(90deg);
  }
  
  .order-symbol, .order-info div {
    text-align: center;
  }