.sidebar {
  position: relative;
  background-color: var(--header-bg-color);
  transition: left 0.3s ease, top 0.3s ease;
  align-items: end;
  overflow-y: auto;
  border-right: 2px solid var(--second-background-color);
  min-width: 250px;
  min-height: 90vh;
}

.sidebar.open {
  top: 0;
  left: 0;
  background-color: var(--background-color);
  width: 250px;
}

/* Прокрутка */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--second-background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--button-hover-bg-color);
}

/*sidebar ul*/
.sidebar ul {
  list-style: none;
  padding: 20px;
}

.sidebar li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  color: var(--text-color);
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
  padding: 10px;
}

.sidebar li .edit-icon {
  color: var(--text-color);
  transition: color 0.3s;
  margin-left: auto; /* Забезпечує відступ іконки праворуч */
}

.sidebar li .edit-icon:hover {
  color: var(--button-bg-color);
}

.sidebar li::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--button-bg-color);
  transition: width 0.3s ease-in-out;
}

.sidebar li:hover::before {
  width: 100%;
}

.sidebar li:hover {
  color: var(--button-bg-color);
  transform: translateY(-2px);
}

.sidebar li:active {
  transform: scale(0.98);
  transition: transform 0.1s ease-in-out;
}

.add-bot-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 20px;
}

.add-bot-button {
  padding: 10px;
  border: none;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-bot-button:hover {
  background-color: var(--button-hover-bg-color);
  transform: scale(1.02);
}

.add-bot-button:active {
  transform: scale(0.98);
}

@media (min-width: 800px) {
  .sidebar {
    position: static;
    top: 0;
    left: 0;
    width: 250px;
  }
}
