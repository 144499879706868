.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: var(--header-bg-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тінь для шапки */
  color: var(--text-color);
}

.logo img {
  max-width: 60px;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.05); /* Невелика анімація при наведенні */
}

.navbar ul {
  display: flex;
  list-style: none;
}

.navbar li {
  margin: 0 20px;
}

.navbar a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}

.navbar a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--button-bg-color);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.navbar a:hover::after {
  width: 100%;
}

.navbar a:hover {
  opacity: 0.6;
}

.header-buttons button {
  font-family: var(--primary-font);
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out, transform 0.3s;
  font-size: 15px;
}

.header-buttons button:hover {
  transform: translateY(-2px); /* Невелика анімація підйому при наведенні */
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

@media (max-width: 1100px) {
  .header {
    padding: 10px 10px;
  }
  .logo img {
    width: 40px;
  }
  .navbar {
    position: absolute;
    top: 8%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    background-color: var(--header-bg-color);
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  .burger-buttons button {
    font-family: var(--primary-font);
    color: var(--button-bg-color);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
    flex: 0.5;
    padding: 20px;
  }
  .burger-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .navbar.active {
    height: 360px;
  }

  .navbar ul {
    flex-direction: column;
    padding: 0;
    padding-left: 20px;
  }

  .navbar li {
    margin: 15px 0;
  }

  .burger-menu {
    display: block;
  }

  .header-buttons {
    display: none;
  }

  .setting-mobile-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 90%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0;
  }
}

/* Додаткові стилі для адаптивності */
@media (max-width: 600px) {
  .navbar a {
    font-size: 14px;
  }

  .burger-menu {
    font-size: 20px;
  }
  .setting-mobile-buttons {
    left: 90%;
  }
}