.container{
  width: 100%;
  overflow: scroll;
}

.bot-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--header-bg-color);
  border-bottom: 2px solid var(--border-color);
  padding: 1.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  gap: 1px;
}

.status-toggle {
  display: flex;
  align-items: center;
}

.status-toggle span {
  margin-right: 10px;
  font-size: 16px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c62828;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #39a53f;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-hint {
  font-size: 12px;
  color: #888;
}

.menu {
  display: flex;
  justify-content: left;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-button {
  padding: 10px 20px;
  background-color: white;
  border: 2px solid transparent;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}

.menu-button:hover {
  background-color: #e9ecef;
  color: #007bff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.HistoryOfOrders {
  padding-top: 20px;
}

@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    gap: 10px;
  }

  .menu-button {
    width: 100%;
    text-align: center;
  }
}
