.bot-edit-container{
  width: 100%;
  overflow: scroll;
}

.bot-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--header-bg-color);
  border-bottom: 2px solid var(--border-color);
  padding: 1rem;
  gap: 16px;
}

.form-input {
  padding: 10px 14px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 20px;
  outline: none;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  max-width: 120px;
}

.form-input:focus {
  border-color: var(--button-bg-color);
  box-shadow: 0 0 5px var(--button-bg-color);
  background-color: var(--focus-background-color);
}

.form-input:hover {
  border-color: var(--button-hover-bg-color);
  background-color: var(--hover-background-color);
}

.form-input:disabled {
  background-color: transparent;
  border: none;
  color: var(--text-color);
}

.form-input:not(:disabled),
.form-select:not(:disabled) {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
}

.form-select {
  padding: 10px 40px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  background-color: var(--background-color);
  color: var(--text-color);
  appearance: none;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill="%23888" d="M0 3l5 5 5-5H0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;

}

.form-select:focus {
  border-color: var(--button-bg-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.form-select:hover {
  background-color: var(--hover-background-color);
  border-color: var(--button-hover-bg-color);
}

.form-select:disabled {
  background-color: var(--disabled-background-color);
  color: var(--disabled-text-color);
  border-color: var(--border-color);
  cursor: not-allowed;
}

.form-select::placeholder {
  color: var(--placeholder-text-color);
}

.form-select option {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  background-color: var(--background-color);
}

.form-select option:hover {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px; /* відступ між інпутом та іконкою */
}

.form-hint {
  font-size: 12px;
  color: #888;
}

.form-label {
  font-size: 20px;
  font-weight: 500;
  color: var(--label-color);
  margin-bottom: 4px;
  display: inline-block;
}

.form-input:focus,
.form-select:focus {
  border-color: var(--button-bg-color);
}

.bot-edit-header button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.bot-edit-header button:hover {
  background-color: var(--button-hover-bg-color);
}

.changes-group {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;
  background-color: var(--header-bg-color);
  justify-content: space-between;
  border-bottom: 2px solid var(--border-color);
}

.status-group {
  display: flex;
  align-items: center;
  background-color: var(--header-bg-color);
}

.status-btn {
  border: none;
  padding: 8px 16px;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.status-btn.active {
  background-color: #e6ffe6;
  color: #2e7d32;
}

.status-btn.stopped {
  background-color: #ffe6e6;
  color: #c62828;
}

.status-btn.active:hover {
  background-color: var(--header-bg-color);
}

.status-btn.stopped:hover {
  background-color: var(--header-bg-color);
}

.status-btn.active::before,
.status-btn.stopped::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-btn.active::before {
  background-color: #2e7d32; /* Зелений */
}

.status-btn.stopped::before {
  background-color: #c62828; /* Червоний */
}

.status-btn-save {
  border: none;
  padding: 8px 150px;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  background-color: var(--button-bg-color);
  color: #ffffff;
}

.status-btn-save:hover {
  background-color: var(--button-hover-bg-color);
  color: #b4eeff;
}

.mass-edit-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 2px solid var(--border-color);
}

.mass-edit-container .general-settings-input {
  width: auto;
  text-align: center;
  font-size: 14px;
  padding: 10px 14px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  outline: none;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--header-bg-color);
}

.general-settings-input:focus {
  border-color: var(--button-bg-color);
  box-shadow: 0 0 5px var(--button-bg-color);
  background-color: var(--focus-background-color);
}

.general-settings-input:hover {
  border-color: var(--button-hover-bg-color);
  background-color: var(--hover-background-color);
}

.form-hint-general-settings{
  padding-top: 8px;
  display: block;
}

.advanced-edit-container{
  gap: 10px;
  font-size: 14px;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
  padding-top: 1%;
}