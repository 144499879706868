.dashboard{
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-container {
  display: flex;
  width: 100%;
  z-index: 0;
}

.sidebar.closed {
  transform: translateX(-100%); /* Приховує Sidebar за межі екрану */
}

.main-content {
  flex-grow: 1; /* Головний контент займає решту доступної ширини */
  background-color: var(--main-bg-color);
  transition: margin-left 0.3s ease-in-out;
}

@media (max-width: 800px) {
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    transform: translateX(-100%);
    margin-top: 0px;
  }

  .sidebar.open {
    transform: translateX(0%);
  }

  .main-content {
    padding: 10px;
  }
}
