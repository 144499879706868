.add-coin-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .add-coin-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
  }
  
  .add-coin-popup h2 {
    margin-top: 0;
  }
  
  .add-coin-popup label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .add-coin-popup input {
    width: 90%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-coin-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 95%;
    margin-left: 2.2%;
  }
  
  .add-coin-actions button {
    padding: 8px 12px;
    min-width: 45%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-coin-actions button[type='button'] {
    background: #f44336;
    color: white;
  }
  
  .add-coin-actions button[type='submit'] {
    background: #4caf50;
    color: white;
  }
  