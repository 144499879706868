.language-switcher {
    position: relative;
    display: inline-block;
  }
  
  .language-switcher-btn {
    border: none;
    border-radius: 10px;
    color: var(--text-color);
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    position: relative; /* Додано для позиціонування галочки всередині кнопки */
  }
  
  .language-switcher-btn:hover {
    background-color: var(--text-color);
    color: var(--background-color);
  }
  
  .language-switcher-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-color);
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 1000;
  }
  
  .language-switcher-menu button {
    background-color: var(--background-color);
    border: none;
    color: var(--text-color);
    padding: 8px 16px;
    padding-left: 40px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    position: relative; /* Додано для позиціонування галочки всередині кнопки */
  }
  
  .language-switcher-menu button.active {
    font-weight: bold;
  }
  
  .language-switcher-menu button:hover {
    background-color: var(--light-blue);
  }
  
  .language-switcher-menu button:focus {
    outline: none;
  }
  
  .checkmark {
    position: absolute;
    right: 50px; /* Перемістити галочку до правого краю кнопки */
    top: 50%;
    transform: translateY(-50%); /* Центрування вертикально */
    font-size: 16px;
    color: var(--text-color);
  }

  @media (min-width: 800px) {
    .language-switcher-btn:focus {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }
  