.statistics-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px;
    border-radius: 8px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .profit-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .profit-section:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
  }
  
  .profit-section-text span {
    padding-right: 15px;
    transition: color 0.3s ease;
  }
  
  .profit-section-text span:hover {
    color: #007bff;
  }
  
  .profit-label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .dropdown {
    padding: 8px 12px;
    font-size: 14px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .dropdown:hover {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  }
  
  .graphic-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  
  .placeholder {
    font-size: 16px;
    font-style: italic;
  }
  
  .orders-history-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .orders-history-section:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
  }
  
  .orders-history-block span {
    padding-right: 15px;
  }
  
  .orders-history-label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .details-toggle-button {
    padding: 8px 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #007bff;
    border-radius: 5px;
    background-color: transparent;
    color: #007bff;
    transition: all 0.3s ease;
    margin-left: 15px;
  }
  
  .details-toggle-button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .orders-summary {
    font-size: 14px;
    color: #555;
    font-weight: 700;
  }
  
  .profit {
    color: #0baa20;
  }
  
  .loss {
    color: #aa0b0b;
  }
  
  .history-module {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 0;
    animation: slideDown 0.3s ease-in-out;
  }

  .dropdown-block span {
    padding-left: 28px;
    padding-right: 8px;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Анімація для приховання модуля */
  .history-module.hidden {
    animation: slideUp 0.3s ease-in-out;
  }
  
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }  