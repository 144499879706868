.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background-color);
    padding: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-form label {
    margin-bottom: 15px;
    font-size: 1rem;
    color: var(--text-color);
}

.login-form input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form input:focus {
    border-color: var(--button-bg-color);
    outline: none;
}

.login-form button {
    padding: 10px;
    font-size: 1.1rem;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form button:hover {
    background-color: #007bb5;
}

@media (max-width: 768px) {
    .login-form {
        padding: 15px;
        width: 90%;
    }

    .login-form label {
        font-size: 0.9rem;
    }

    .login-form input {
        font-size: 0.9rem;
    }

    .login-form button {
        font-size: 1rem;
    }
}
