:root {
  --primary-font: 'Comfortaa';
  
  /* Світла тема */
  --background-color: #f9f9f9;
  --second-background-color: #e0e0e0;
  --text-color: #222222;
  --header-bg-color: #ffffff;
  --button-hover-bg-color: #2D9CDB; /* Основний колір кнопок */
  --button-text-color: #222222;
  --border-color: #dcdcdc;
  --button-bg-color: #34b4df; /* Колір для hover ефекту кнопок */
  --accent-color: #6FCF97; /* Акцентний колір */
}

.dark-mode {
  /* Темна тема */
  --background-color: #0B0C10;
  --second-background-color: #1F2833;
  --text-color: #C5C6C7;
  --header-bg-color: #05060e;
  --button-hover-bg-color: #00d323; /* Основний колір кнопок */
  --button-text-color: #C5C6C7;
  --border-color: #C5C6C7;
  --button-bg-color: #01d135; /* Колір для hover ефекту кнопок */
  --accent-color: #0da100; /* Акцентний колір */
}
